<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <Table
      rowKey="id"
      :isLoading="$store.getters['meetings/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['meetings/get'](params)"
    >
      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import pick from 'lodash/pick';
import TableUtil from '../../../../mixins/TableUtil';
import Schedule from '../../../../store/models/Schedule';

import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';
import TableActions from '../../../../components/teacher/schedule/meeting/TableActions.vue';
import Table from '../../../../components/table/Table.vue';

export default {
  mixins: [TableUtil],

  components: { ScheduleInfo, Table },

  computed: {
    params() {
      return {
        filter: pick(this.schedule, ['grade_id', 'subject_id']),
      };
    },

    columns() {
      return [
        {
          key: 'no',
          text: 'No',
          toHTML: (row, index) => this.getNumber(index),
        },
        {
          key: 'date',
          text: 'Tanggal',
          toHTML: (row) => row.dateJS.format('D MMMM YYYY'),
        },
        {
          key: 'attendances.length',
          text: 'Jumlah Hadir',
          toHTML: (row) =>
            `${row.attendances.length} <small class="text-muted">/ ${row.grade.students_count}</small>`,
        },
        {
          key: 'actions',
          text: '',
          component: TableActions,
          props: { class: 'text-end' },
        },
      ];
    },

    schedule() {
      return Schedule.query().withAllRecursive().find(this.$route.params.id);
    },

    meta() {
      return this.$store.getters['meetings/getMeta'](this.params);
    },
  },

  methods: {
    fetch() {
      this.$store.dispatch('meetings/maybeFetch', this.params);
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  metaInfo() {
    return { title: 'Pertemuan' };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.fetch();

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
    ]);
  },
};
</script>
