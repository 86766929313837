<template>
  <router-link
    class="btn btn-sm btn-outline-secondary d-inline-flex align-items-center"
    :to="`/guru/jadwal/${$route.params.id}/pertemuan/${row.id}/presensi`"
  >
    <ListIcon class="me-8" />
    Presensi
  </router-link>
</template>

<script>
import ListIcon from 'bootstrap-icons/icons/list.svg';

export default {
  components: { ListIcon },

  props: ['row'],
};
</script>
